<template>
    <div class="flex grow select-none flex-col items-center justify-center text-center text-2xl">
        <h1>
            <Icon name="magnify" size="82" class="text-base-content/30" />
        </h1>
        <p class="mb-6 mt-2 text-base-content/50">
            Page Not Found.
        </p>
        <NuxtLink v-if="$route.from" :to="$route.from">
            <button class="btn btn-primary">
                Go Back
            </button>
        </NuxtLink>
        <NuxtLink v-else
                  :to="{ path: '/' }">
            <button class="btn btn-primary">
                Go To Home Page
            </button>
        </NuxtLink>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
// todo - redirect could be an issue here?

export default defineComponent({
    // eslint-disable-next-line vue/component-definition-name-casing
    name: '[...all]'
});
</script>
